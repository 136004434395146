<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Top up your account</h4>
      <div class="mt-4">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <router-link :to="{ query: { tab: 'mpesa' }}" :class="`nav-link ${show_tab == 'mpesa' ? 'active' : ''}`">Mpesa</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ query: { tab: 'card' }}" :class="`nav-link ${show_tab == 'card' ? 'active' : ''}`">Card</router-link>
          </li>
          <li class="nav-item" v-if="user.role == 'admin'">
            <router-link :to="{ query: { tab: 'admin' }}" :class="`nav-link ${show_tab == 'admin' ? 'active' : ''}`">Admin</router-link>
          </li>
        </ul>
        <div class="row">
          <div class="col-md-7">
            <div class="tab-content">
              <div id="mpesa" :class="`tab-pane ${show_tab == 'mpesa' ? 'active' : ''}`">
                <div>
                  <img src="/m-pesa.png" alt="mpesa" height="100px" />
                </div>
                <div>
                  <p>Follow the instructions below:</p>
                  <ol>
                    <li>Go to m-pesa menu</li>
                    <li>Select lipa na mpesa</li>
                    <li>Select pay bill</li>
                    <li>
                      Enter paybill number
                      <strong>625625</strong>
                    </li>
                    <li>
                      Enter account number
                      <strong
                        style="text-transform: uppercase; letter-spacing: 2px"
                        >01521223860700</strong
                      >
                    </li>
                    <li>
                      Enter the amount you wish to spend
                    </li>
                    <li>Enter your pin and send</li>
                  </ol>
                </div>
              </div>
              <div id="card" :class="`tab-pane ${show_tab == 'card' ? 'active' : ''}`">
                <p>Make a bank deposit to National bank account number <strong>01521223860700</strong></p>
              </div>
              <div id="Admin" :class="`tab-pane ${show_tab == 'admin' ? 'active' : ''}`" v-if="user.role == 'admin'">
                <form @submit.prevent="submitAdmin">
                  <div class="form-group">
                    <label>Enter amount</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="admin_amount"
                      placeholder="amount"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary">Top Up</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      admin_amount: "",
    };
  },

  methods: {
    submitAdmin() {
      let data = {
        amount: this.admin_amount
      }

      this.$loader.start('main-content')
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/admin-top-up`, data).then(response => {
        this.$store.dispatch('setApp', response.data.current_app)
        this.$axios.stop()
      }).catch(() => {
        this.$loader.stop()
      })
    }
  },
  
  computed: {
    show_tab() {
      return this.$route.query.tab == null ? 'mpesa' : this.$route.query.tab
    },

    user() {
      return this.$store.getters.getAuthUser
    }
  }
};
</script>
